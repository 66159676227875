import React, { Fragment } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import OthersPageContain from '../../Component/OthersPage';

const OthersPage = () => {
    return (
        <Fragment>
            <Breadcrumbs parent="Pages" title="" />
            <OthersPageContain />
        </Fragment>
    );
};
export default OthersPage;